import "htmx.org";
import StoryblokClient from "storyblok-js-client";

//
// Navigation
//

// create nav item
function createNavItem(url, text) {
  return `
    <li class="js-navItem hover:text-grey hover:underline cursor-pointer"
      hx-get="${url}"
      hx-target="#page-content"
      hx-trigger="click"
      hx-swap="innerHTML"
      tabindex="0"
    >
      ${text}
    </li>
  `;
}

const navList = document.querySelector(".js-navList");
navList.innerHTML += createNavItem("/pages/the-brand.html", "The Brand");
navList.innerHTML += createNavItem("/pages/logos.html", "Logos");
navList.innerHTML += createNavItem("/pages/colors.html", "Colors");

// nav item active state
document.querySelectorAll(".js-navItem").forEach((item) => {
  item.addEventListener("click", (event) => {
    document.querySelectorAll(".js-navItem").forEach((link) => {
      link.classList.remove("underline", "text-grey");
    });

    event.currentTarget.classList.add("underline", "text-grey");
  });
});

//
// Storyblok
//

// new StoryblokClient instance
const Storyblok = new StoryblokClient({
  accessToken: "DYmXieaX9QQQVJkt9BFyEQtt",
});

// render intro
function renderIntro(component, storyContainer) {
  storyContainer.innerHTML += `
    <h1 class="top-10 z-20 mb-14 max-w-fit static text-5xl">${component.introTitle}</h1>
    <p class="text-base">${component.introText}</p>
  `;
}

// switch case between field types
function renderContent(item, storyContainer) {
  let html;
  switch (item.component) {
    case "titleText":
      html = Storyblok.richTextResolver.render(item.text);

      storyContainer.innerHTML += `
        <h3 class="first:mb-9 first:text-3xl text-2xl mb-3">${item.title}</h3>
        <div class="text-sm xl:w-5/6 w-full mb-20 leading-relaxed">
          ${html}
        </div>
      `;
      break;

    case "keywords":
      const keywordsArray = item.keywords.split(",");
      storyContainer.innerHTML += `
        <div class="text-lg flex flex-wrap mb-20">
          ${keywordsArray.map((keyword) => `<span class="h-8 relative -top-5 flex items-center justify-center w-auto px-8 mb-4 mr-4 border border-black rounded-full">${keyword.trim()}</span>`).join("")}
        </div>
      `;
      break;

    case "image":
      storyContainer.innerHTML += `
        <img class="image w-full" src="${item.image.filename}" alt="${item.imageText}" loading="lazy"/>
        <div class="image__caption flex justify-between items-center mt-4 mb-20">
          <span class="text-grey text-xs max-w-[85%]">
            ${item.imageText}
          </span>
            <div tabindex="0">
              <a class="image__download  text-grey text-xs self-start"
                href="${item.image.filename}" target="_blank"
              >
                ↓ Download
              </a>
            </div>
        </div>
      `;
      break;

    case "sectionColors":
      html = `<div class="mb-28 flex xl:h-[1600px] h-[1000px] flex-col">`;
      for (let i = 0; i < item.colors.length; i++) {
        html += `
          <div class="color__box w-full grow flex justify-center  items-center" style="background-color: ${item.colors[i].colorValue};">
            <span class="color__caption opacity-0 transition text-xl text-black bg-white max-w-[85%]">
              ${item.colors[i].colorValue}
            </span>
          </div>
        `;
      }
      html += `</div>`;

      storyContainer.innerHTML += html;
      break;
  }
}

// render section
function renderSection(component, storyContainer) {
  storyContainer.innerHTML += `<hr class="my-16 border-black" />`;
  const content = component.content;

  let section = document.createElement("section");
  content.forEach((item) => {
    renderContent(item, section);
  });
  storyContainer.appendChild(section);
}

// get all stories, render intro and sections
function renderStories() {
  Storyblok.getAll("cdn/stories", {
    version: "published",
  })
    .then((response) => {
      const stories = response;

      stories.forEach((story) => {
        const components = story.content.body;
        const storyContainer = document.querySelector(`#${story.slug}`);
        if (!storyContainer) {
          return; // skipping story not yet loaded
        }

        components.forEach((component) => {
          switch (component.component) {
            case "intro":
              renderIntro(component, storyContainer);
              break;

            case "section":
              renderSection(component, storyContainer);
              break;
          }
        });

        storyContainer.innerHTML += `<hr class="mt-16 mb-32 xl:w-5/6 w-full border-black" />`;
      });
    })
    .catch((error) => {
      console.log(error);
    });
}

// reinitializing stories after htmx swap
document.addEventListener("htmx:afterSwap", function () {
  renderStories();
});
